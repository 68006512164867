/* eslint no-param-reassign: 0 */
import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import { frontendURL } from '../../helper/URLHelper';
import {
  ANALYTICS_IDENTITY,
  ANALYTICS_RESET,
  CHATWOOT_RESET,
  CHATWOOT_SET_USER,
} from '../../helper/scriptHelpers';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (user, expiryDate, options = {}) => {
  if (options && options.setUserInSDK) {
    window.bus.$emit(CHATWOOT_SET_USER, { user });
    window.bus.$emit(ANALYTICS_IDENTITY, { user });
  }
  // add onboard_limit to all cookie
  user.onboard_limit = user.onboard_limit || 0;
  // remove unnecessary keys
  const filteredUser = {
    access_token: user.access_token,
    account_id: user.account_id,
    step: user.step,
    trial: user.trial,
    onboard_limit: user.onboard_limit,
    payment: user.payment,
    source: user.source,
    account_message_limit: user.account_message_limit,
    phone_number: user.phone_number,
    account_top_amount: user.account_top_amount,
    total_top_amount: user.total_top_amount,
    low_credit_warning: user.low_credit_warning,
    stripe_customer_id: user.stripe_customer_id,
    plan_id: user.plan_id,
    sector: user.sector,
    chat: user.chat,
    order: user.order,
    appsumo: user.appsumo,
    end_date: user.end_date,
    available_name: user.available_name,
    avatar_url: user.avatar_url,
    default_currency: user.default_currency,
    confirmed: user.confirmed,
    user_phone_number: user.user_phone_number,
    type: user.type,
    display_name: user.display_name,
    message_signature: user.message_signature,
    email: user.email,
    id: user.id,
    inviter_id: user.inviter_id,
    name: user.name,
    provider: user.provider,
    pubsub_token: user.pubsub_token,
    role: user.role,
    uid: user.uid,
    pm_access_token: user.pm_access_token,
    pm_account_id: user.pm_account_id,
    sst_rate: user.sst_rate,
    custom_attributes: user.custom_attributes,
    ui_settings: user.ui_settings,
    accounts: user.accounts,
    created_at: user.created_at,
  }
  // console.log({user,filteredUser});
  Cookies.set('user', filteredUser, {
    expires: differenceInDays(expiryDate, new Date()),
  });
};

export const getHeaderExpiry = response =>
  fromUnixTime(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  Cookies.set('auth_data', response.headers, {
    expires: differenceInDays(expiryDate, new Date()),
  });
  setUser(response.data.data, expiryDate);
};

export const clearCookiesOnLogout = () => {
  window.bus.$emit(CHATWOOT_RESET);
  window.bus.$emit(ANALYTICS_RESET);

  // hardcodedly reset $peasy. don't wait for event
  if (window.$peasy) {
    window.$peasy.reset();
    console.log("Reseted $peasy");
  }
  if (window.analyticsConfig) {
    posthog.reset();
    console.log("Reseted posthog");
  }


  Cookies.remove('auth_data');
  Cookies.remove('user');

  console.log("Emmitted CHATWOOT_EVENTS on lgoout");

  const globalConfig = window.globalConfig || {};
  const logoutRedirectLink =
    globalConfig.LOGOUT_REDIRECT_LINK || frontendURL('login');
  window.location = logoutRedirectLink;
};
