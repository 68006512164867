<template>
  <div class="add_btns">
        <div>
            <div class="custom-btn-wrapper">
                <button class="btn" @click="addOutstandingAppointment">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />
                {{ $t('FLOW.OUTSTANDING_APPOINTMENT') }}
                </button>
            </div>
        </div>
        <!-- outstanding appointment selected card details -->
          <div>
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addAppointmentCard">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />
                {{ $t('FLOW.APPOINTMENT_CARD') }}
              </button>
            </div>
          </div>
  </div>
</template>

<script>
import { Node } from '../../vue-diagram-editor/src';

export default {
    props:{
        node: {
            type: Node,
            required: true,
        },
        dref: {
            required: true,
        },
        contents:{
            required: true,
            default: []
        },
        isCombine: {
            required: false,
            default: false
        },
        currentUser: {
            required: false,
            default: null
        }
    },
    methods:{
        addOutstandingAppointment(){
            this.contents.push({
                // value: '',
                type: 'outstanding_appointment',
                title: '',
                body: '',
                footer: '',
                button: '',
                section_title: '',
                account_id: '{{account_id}}',
                access_token: '{{access_token}}',
            });

            if (this.isCombine) {
                this.dref.updateCombineNode({
                    id: 'combine-' + this.node.id.split('-')[1],
                    subNodeId: this.node.id,
                    name: 'contents',
                    value: this.contents,
                });
            } else {
                this.dref.updateNode({
                    id: this.node.id,
                    name: 'contents',
                    value: this.contents,
                });
            }
        },
        addAppointmentCard(){
            // push card but with sub type

            this.contents.push({
                // value: '',
                type: 'appointment_card',
                title: '',               // title+subtitle will be added from botpress
                subtitle: '',
                actionButtons: [],
                // reschedule_btn:'',
                // cancel_btn:'',
                account_id: '{{account_id}}',
                access_token: '{{access_token}}',
            });

            if (this.isCombine) {
                this.dref.updateCombineNode({
                    id: 'combine-' + this.node.id.split('-')[1],
                    subNodeId: this.node.id,
                    name: 'contents',
                    value: this.contents,
                });
            } else {
                this.dref.updateNode({
                    id: this.node.id,
                    name: 'contents',
                    value: this.contents,
                });
            }
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>
.add_btns{
    display: flex;
    column-gap: 5px;
}
</style>