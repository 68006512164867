<template>
  <div class="column content-box onboard_container">
    <section class="row">
      <div class="column content-box">

        <!-- whatsapp verification -->
        <section class="business_container verify_container" v-if="!currentUser.confirmed">
          <div class="banner_heading">
              <h3 class="page-title">{{$t('ARRANGE.VERIFY.TITLE')}}</h3>
          </div>
          <div>
            <p style="margin:0;">{{$t('ARRANGE.VERIFY.MSG_PART_1')}}</p>
            <p><b>{{currentUser.user_phone_number}}</b> {{$t('ARRANGE.VERIFY.MSG_PART_2')}}</p>
            <woot-button class="wp_btn" @click="handleOpenWhatsApp">{{$t('ARRANGE.VERIFY.WHATSAPP_BTN')}}</woot-button>
            <div class="resend_box">
              <p style="margin:0;">{{$t('ARRANGE.VERIFY.RESEND_TEXT')}}</p>
              <woot-button
              class="resend_btn"
              :classNames="isResendEnabled ? '' : 'btn_mute'"
              :disabled="!isResendEnabled || isReSending"
              @click="handleResendLink"
              >
              {{ isResendEnabled ? $t('ARRANGE.VERIFY.RESEND_BTN') : `${$t('ARRANGE.VERIFY.RESEND_BTN')} (${countdown}s)` }}
              </woot-button>
            </div>
          </div>
        </section>

        <!-- redesign the process  -->
        <div v-else>
          <section v-if="showOrderForm" class="business_service">
            <h2 class="title">{{$t('ARRANGE.BUSINESS_TYPE.ORDER_LEVEL')}}</h2>
            <div class="services">
              <woot-input
                v-model.trim="average_order"
                type="number"
                :class="{ error: $v.average_order.$error }"
                :label="''"
                :placeholder="'500'"
                :error="
                  $v.average_order.$error
                    ? $t('ARRANGE.BUSINESS_TYPE.ORDER_ERR')
                    : ''
                "
                @blur="$v.average_order.$touch"
              />
            </div>
            <div class="btn_wrap">
              <woot-button
                color-scheme="primary"
                :is-loading="isLoading"
                :is-disabled="isLoading"
                @click="onBusinessSubmit"
              >
                {{$t('ARRANGE.BUSINESS_TYPE.BTN')}}
              </woot-button>
            </div>
          </section>
          <section v-else class="business_service">
            <h2 class="title">{{$t('ARRANGE.BUSINESS_SERVICES.TITLE')}}</h2>
            <ul class="services">
              <li v-for="item in businessServices" class="service" :class="active_service && active_service.id === item.id ?'active_service':''" :key="item.id" @click="onBusinessClick(item)">
                <div class="icon">
                  <div>
                    <fluent-icon :icon="item.icon" :viewBox="item.viewBox" />
                  </div>
                </div>
                <div class="info">
                  <h4>{{$t(item.name)}}</h4>
                  <p v-if="active_service && active_service.id === item.id">{{$t(item.description)}}</p>
                </div>
              </li>
            </ul>
            <div class="btn_wrap">
              <woot-button :isDisabled="!active_service" @click="onServiceNext">{{$t('ARRANGE.BUSINESS_SERVICES.BTNS.NEXT')}}</woot-button>
            </div>
          </section>
        </div>


      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import {businessTypes,services,businessServices} from "./constant"
import RadioList from './RadioList.vue';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from "../../../api/account"
import { mapGetters } from 'vuex';
import {getParamsFromUrl, frontendURL} from "../../../helper/URLHelper"
import {SIGNUP_FLOW} from "../../../helper/demoAutomationHelper"
import endPoints from '../../../api/endPoints';
import Button from '../../../components/buttons/Button.vue';
import WootButton from '../../../components/ui/WootButton.vue';

export default {
  components: {
    RadioList,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      businessServices,
      active_service: null,
      showOrderForm:false,
      isLoading: false,
      average_order: 0,
      countdown: 180, // 3 minutes countdown
      isResendEnabled: false,
      isReSending: false,
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      userPlan: 'accounts/getUserPlan',
      getCurrentAccount: 'accounts/getAccount',
    }),
  },
  mounted() {
    this.startCountdown();
    bus.$on('WhatsAppVerificationBusEvent', (data) => {
      console.log("verify res = ",data);
      if (data?.confirmed) {
        // if already have sector, then go to home
        if(this.currentUser.sector){
          window.location = `/app/accounts/${this.currentUser.account_id}/`
          return
        }
        window.location.reload()
      }
    });
    this.updateQueryParamsIfNeeded();
  },
  watch: {
    countdown(newCountdown) {
      if (newCountdown <= 0) {
        this.isResendEnabled = true;
      }
    },
  },
  validations: {
    average_order: {
      required,
      isNumeric(value){
        return /^[0-9]+$/.test(value);
      }
    },
  },
  methods: {
    async onBusinessSubmit(){
      const query = getParamsFromUrl();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }


      try {
        this.isLoading = true;

        const payload = {
          sector: this.active_service.sector,
          average_order:  parseInt(this.average_order),
        }

        const {data:attrRes} = await AccountAPI.setCustomAttributeToAdminContact(payload);

        await this.$store.dispatch('accounts/get'); // get updated account
        const nextsignupflow = {
            account_id:this.currentUser.account_id,
            isNewSignup:true,
            current_step: SIGNUP_FLOW.FLOWS.select_plan
          }
        SIGNUP_FLOW.setFlow(nextsignupflow)

        window.location = frontendURL(endPoints('subscriptionPlans').url(this.currentUser.account_id),query)

        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_SUCCESS_MSG'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_FAIL_MSG'));
      } finally{
        this.isLoading = false;
      }

    },
    onBusinessClick(item){
      this.active_service = item;
    },
    onServiceNext(){
      this.showOrderForm = true;
    },
    onBusinessTypeChange(index) {
      console.log('Selected business type:', index);
    },
    handleOpenWhatsApp() {
      const message = this.$t('ARRANGE.VERIFY.WHATSAPP_RESEND_SUCCESS_MSG');
      const phone = this.currentUser.phone_number;
      const url = `whatsapp://send?text=${encodeURIComponent(message)}&phone=${encodeURIComponent(phone)}`;
      window.location.href = url;  // This will trigger WhatsApp to open
    },
    async handleResendLink() {
      if (!this.isResendEnabled || this.isReSending) return;

      this.isReSending = true;
      try {
        await AccountAPI.resendVerificationCode();
        this.countdown = 180;  // Reset countdown to 3 minutes
        this.isResendEnabled = false;
        this.showAlert(this.$t('ARRANGE.VERIFY.RESEND_SUCCESS'));
      } catch (error) {
        console.error(error);
        this.showAlert(error.message || "Failed to send verification code.");
      } finally {
        this.isReSending = false;
      }
    },
    startCountdown() {
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(interval);  // Stop the countdown when it reaches 0
        }
      }, 1000);
    },
    updateQueryParamsIfNeeded() {
    const query = { ...this.$route.query };
    const signup_flow = SIGNUP_FLOW.getFlow();
    if (signup_flow?.buy_now_plan_id && signup_flow?.isNewSignup && !query.buy_now_plan_id) {
      query.buy_now_plan_id = signup_flow.buy_now_plan_id;
      console.log({query});
      this.$router.push({ path: this.$route.path, query });
    }
  }
    // end method
  },
}
</script>

<style scoped lang="scss">
.verify_container{
  .wp_btn{
    font-weight: 700;
    color: var(--w-500);
    background: transparent;
    margin: 25px 0;
  }
  .resend_box{
    display: flex;
    justify-items: center;
    align-items: center;

    .resend_btn{
      background: transparent;
      color: var(--color-body);
    }
    .btn_mute {
      color: #000;
    }
  }
}
.business_container{
  min-height: 100vh;
  width: fit-content;
  margin: auto;

  .sub_title{
    font-weight: 600;
    margin-top: 16px;
  }
  .btn_wrap{
    margin-top: 20px;
  }
}

.business_service{
  max-width: fit-content;
  margin: auto;
  font-family: 'Figtree';
  .title{
    font-family: 'Figtree';
    font-size: 24px;
    font-weight: 700;
    color: #1F1F1F;
  }
  .services{
    list-style: none;
    margin-top: 24px;
    width: 500px;
    .service{
      display: grid;
      grid-template-columns: 22px 1fr;
      gap: 8px;
      padding: 12px 16px;
      margin-top: 12px;
      border: 1px solid #EEEEEE;
      color: #8D25F6;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .info{
        h4{
          font-family: 'Figtree';
          font-weight: 600;
          font-size: 16px;
          color: #8D25F6;
        }
        p{
          font-weight: 500;
          font-size: 14px;
          margin-top: 4px;
          color: #1F1F1F;
        }
      }
    }
    .active_service{
      border-radius: 6px;
      background-color: #F2E7FE;
      border-color: #8D25F6;
    }
  }
  .btn_wrap{
    width: fit-content;
    margin-top: 40px;
  }
}

</style>