<template>
  <div class="col_preview preview">
    <div class="pre_header">
        <h3 class="title">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.PREVIEW_TITLE")}}</h3>
    </div>
    <div class="pre_message_box">
        <div class="preview_card">
            <!-- do loop on content  -->
            <div v-for="(content,idx) in currentForm.contents" :key="idx">
                <div v-if="content.type === 'text'" class="content">
                    <p>{{content.value}}</p>
                </div>

                <div v-if="content.type === 'card'" class="content">
                    <p>{{content.title}}</p>
                    <p>{{content.subtitle}}</p>
                    <div v-if="content.actionButtons.length" class="buttons">
                        <div class="btn_wrapper">
                            <template v-for="buttonEl in content.actionButtons" >
                                <a v-if="buttonEl.visibility && buttonEl.editable" :key="buttonEl.title">
                                {{ buttonEl.title }}
                                </a>
                            </template>
                        </div>
                    </div>
                </div>

                <div v-if="content.type === 'category_list'" class="content">
                    <p>{{content.title}}</p>
                    <p>{{content.body}}</p>
                    <p>{{content.footer}}</p>

                    <div class="buttons">
                        <div class="btn_wrapper">
                            <a>
                            {{ content.button }}
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="content.type === 'image'" class="content">
                    <p>{{content.title}}</p>
                </div>

                <div class="content">
                    <time>{{getCurrentTime()}}</time>
                </div>
            </div>


        </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";


export default {
    props:{
        currentForm: {
            type: Object,
            default: {
                contents:[]
            }
        },
    },
    data(){
        return {
            content: '',
            file: '',
            filePreviewUrl:"",
            buttonsData: [],
        }
    },
    computed:{
        fileType(){
            if (!this.file) {
                return{
                    isImage: false,
                    isVideo: false,
                    isPDF: false
                };
            }
            const isImage = this.file?.type?.startsWith('image');
            const isVideo = this.file?.type?.startsWith('video/mp4');
            const isPDF = this.file?.type?.startsWith('application/pdf');
            console.log({
                type: this.file.type,
                isImage,
                isPDF,
                isVideo,
            });

            return {
                isImage,
                isVideo,
                isPDF,
            }
        },
    },
    methods:{
        getCurrentTime() {
            const now = moment();
            return now.format('h:mm A'); // 8:04 AM
        },
    },
}
</script>

<style lang="scss" scoped>

// preview start
.col_preview{
    position: relative;
    min-height: 100%;
    background-image: url('../../../../assets/images/background/whatsapp_bg.jpeg');
    background-size: cover;
}
.col_preview::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02);
}

.preview{
    .pre_header{
        padding: var(--space-slab);
        background-color: #F2E7FE;
        .title{
            font-weight: 700;
            font-size: var(--font-size-small);
            color: #8D25F6;
        }
    }
    .pre_message_box{
        margin: var(--space-two) var(--space-slab);
    }
}

.preview_card{
    position: relative;
    max-width: 90%;
    margin: 30px auto;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    max-width: 350px;
    min-width: 250px;

    .arrow_mark{
        width: 20px;
        height: 20px;
        background: #fff;
        position: absolute;
        top: 0;
        left: -15px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }

    .media{
        max-height: 220px;
        overflow: hidden;

        .file_view_wrapper{
            position: relative;
            width: 100%;
            height: 220px;

            img,video,iframe{
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .content{
        position: relative;
        min-height: 10px;
        margin-bottom:  var(--space-small);
        padding: var(--space-one) var(--space-slab);
        p{
            white-space: pre-wrap;
            margin: 0;
            font-size: 12px;
        }
        .placeholder{
            color: #BBBBBB;
            font-size: var(--font-size-small);
            font-weight: 500;
        }
        time{
            position: absolute;
            // width: 60px;
            width: fit-content;
            height: 10px;
            right: 0;
            bottom: -5px;
            font-size: 12px;
            color: #8492a6;
        }
    }
    .buttons{

        .btn_wrapper{
            padding: 4px;

            a{
                display: block;
                text-align: center;
                padding: 5px;
                border-top: 1px solid #ebf0f5;
            }
        }
    }
}
// preview end


</style>