<template>
    <div v-if="content.type == 'outstanding_appointment'" class="card_node_item">
        <span v-if="content.title" style="color:black">
        {{ 'Outstanding Appointment | ' + content.title }}
        </span>
        <span v-else style="color:grey">
        {{ $t('FLOW.APPOINTMENT_LIST.EDIT_CLICK_OUTSTANDING') }}
        </span>
        <div style="display:flex">
            <woot-button
                size="small"
                variant="smooth"
                color-scheme="secondary"
                icon="edit"
                style="margin-right: 5px;"
                @click="openEditModal(content)"
            />
            <woot-button
                size="small"
                variant="smooth"
                color-scheme="alert"
                icon="dismiss"
                @click="deleteItem(index)"
            />
        </div>

        <woot-modal
            :show.sync="showEditModal"
            :on-close="closeEditModal"
            custom-class="height"
            >
            <EditOutstandingAppointment
                :list="this.content"
                :dref="this.dref"
                :botid="this.botid"
                @on-close="closeEditModal"
                @list-edit-done="onEditDone"
            />
        </woot-modal>
    </div>
    <div v-else-if="content.type == 'appointment_card'" class="card_node_item">
        <span v-if="content.reschedule_btn" style="color:black">
        {{ 'Appointment Card | ' + content.reschedule_btn }}
        </span>
        <span v-else style="color:grey">
        {{ $t('FLOW.APPNMT_CARD.EDIT_CLICK_BTN') }}
        </span>
        <div style="display:flex">
            <woot-button
                size="small"
                variant="smooth"
                color-scheme="secondary"
                icon="edit"
                style="margin-right: 5px;"
                @click="openEditModal(content)"
            />
            <woot-button
                size="small"
                variant="smooth"
                color-scheme="alert"
                icon="dismiss"
                @click="deleteItem(index)"
            />
        </div>

        <woot-modal
            :show.sync="showEditModal"
            :on-close="closeEditModal"
            custom-class="height"
            >
            <!-- show regular card edit component, no -->
            <!-- <EditAppointmentCard
                :content="this.content"
                :dref="this.dref"
                :botid="this.botid"
                @on-close="closeEditModal"
                @content-edit-done="onEditDone"
            /> -->
            <edit-card
                :card="this.content"
                :dref="this.dref"
                :botid="this.botid"
                @on-close="closeEditModal"
                @card-edit-done="onEditDone"
            />
        </woot-modal>

    </div>
</template>

<script>
import EditOutstandingAppointment from "./EditOutstandingAppointment.vue";
import EditAppointmentCard from "./EditAppointmentCard.vue";
import EditCard from './EditCard.vue';

export default {
    components:{
        EditOutstandingAppointment,
        EditAppointmentCard,
        EditCard,
    },
    props:{
        dref: {
            required: true,
        },
        botid: {
            required: true,
        },
        content:{
            required: true,
            default: {},
        },
        index:{
            required: true,
        },
        deleteItem:{
            required: true,
            default: ()=>{},
        }
    },
    data(){
        return {
            showEditModal: false,
        }
    },
    methods:{
        openEditModal(content){
            this.showEditModal = true;
        },
        closeEditModal(){
            this.showEditModal = false;
        },
        onEditDone(){
            this.closeEditModal();
        },
        // end of methods
    },
}
</script>

<style>

</style>