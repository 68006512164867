<template>
  <div>
    <ModalTemplate
      :isOpen="isOpen"
      :on-close="onClose"
      modalMainContainerClass="taskCompleteModal"
      closeBtnClass="modal_close_btn_left"
      closeIcon="chevron-left"
      :title="$t('D_BOARD.COMPLETE_TASKS_MODAL.TITLE')"
    >
        <div class="task_modal_title">
            <h2>{{$t('D_BOARD.COMPLETE_TASKS_MODAL.CONTENT_TITLE_1')}}</h2>
            <h2>{{$t('D_BOARD.COMPLETE_TASKS_MODAL.CONTENT_TITLE_2')}}</h2>
        </div>
        <div class="logo_wrap">
            <div class="logo">
                <!-- <img src="~dashboard/assets/images/lavel_logo_placeholder.png" alt=""> -->
                <img v-if="score.rank_logo" :src="score.rank_logo" alt="">
                <img v-else src="~dashboard/assets/images/octo_img.png" alt="">
            </div>
        </div>
        <div class="tasks_list">
            <ul>
                <li v-for="task in score.level_events" :key="task.title">
                    <img class="bg_img" src="~dashboard/assets/images/task_mask_img.png" alt="">
                    <h4 class="title">
                        {{task.name}}
                        <span v-if="task.completed" class="check_icon">
                            <fluent-icon icon="checkmark" />
                        </span>
                    </h4>
                    <p class="description">{{task.description}}</p>
                    <div class="task_btns">
                        <a :href="task.kb_url" target="_blank">
                            <woot-button class-names="button--dashboard-outline task_btn task_btn_outline" variant="outline">
                                {{$t('D_BOARD.COMPLETE_TASKS_MODAL.LEARN_BTN')}}
                            </woot-button>
                        </a>
                        <a :href="task.page_url">
                            <woot-button :disabled="task.completed" class-names="task_btn" variant="primary">
                                {{$t('D_BOARD.COMPLETE_TASKS_MODAL.START_BTN')}}
                            </woot-button>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- <div  style="margin-top: 24px;">
        <woot-button class-names="button--dashboard-outline clode_sq_modal" variant="outline" @click="onClose">
          {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE")}}
        </woot-button>
      </div> -->
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalTemplate from '../../../components/ModalTemplate.vue';


export default {
    components:{
        ModalTemplate,
    },
    props:{
        isOpen: {
        type: Boolean,
        default: false
        },
        onClose: {
        type: Function,
        default: () => {},
        },
    },
    data(){
        return{
        }
    },
    computed: {
        ...mapGetters({
            score: 'score/getScore',
        }),
    },
    methods:{
        // navigateLearnClick(task){
        //     const url = task.kb_url;
        // },
        // navigateStartClick(task){
        //     const url = task.page_url;
        // },
    },
}
</script>

<style lang="scss" scoped>
.task_modal_title{
    margin-top: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}
.logo_wrap{
    margin-top: 32px;
    .logo{
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.tasks_list{
    margin-top: 32px;
    ul{
        margin: 0;
        li{
            position: relative;
            overflow: hidden;
            padding: 20px;
            border-radius: 16px;

            background: linear-gradient(180deg,#FFFFFF80,#D9D9D980);
            &:not(:first-child){
                margin-top: 16px;
            }
            .bg_img{
                position: absolute;
                right: -20px;
                bottom: -20px;
                width: 72px;
                height: 80px;
            }

            .title{
                position: relative;
                font-weight: 600;
                font-size: 16px;
                color: #1F1F1F;
                .check_icon{
                    position: absolute;
                    color: #24B200;
                    right: -18px;
                    top: 0;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .description{
                font-weight: 500;
                font-size: 14px;
                color: #858585;
                margin-top: 4px;
            }
            .task_btns{
                margin-top: 16px;
                display: flex;
                gap: 16px;
            }
        }
    }
}
</style>

<style>
/* // global css */
.taskCompleteModal{
    width: 440px;
    /* background-color: #F2E7FE; */
    background-image: url('~dashboard/assets/images/task_complete_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.taskCompleteModal .task_btn{
    font-size: 14px;
}
.taskCompleteModal .task_btn_outline{
    background: transparent !important;
}

</style>