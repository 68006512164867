import mixpanel from 'mixpanel-browser'


// create an instance of the Mixpanel object
const MIXPANEL_PROJECT_ID= 'baef931e1d6582743b85735b54186b6e';

// Ensure Mixpanel is only initialized once
if (!window.mixpanel) {
    console.log("Initializing mizPanel");
    mixpanel.init(MIXPANEL_PROJECT_ID, {
      persistence: 'localStorage',
      debug: true,
    });

    window.mixpanel = mixpanel;
  }

export default mixpanel;
