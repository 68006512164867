<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px; padding-left: 24px; padding-right: 24px; color:grey; align-items: center;"
    >
      <h5>{{ $t('FLOW.APPNMT_CARD.HEAD') }}</h5>
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <!-- Title and subtitle will be added from botpress API call -->
        <!--
         <woot-input
            v-model="content.title"
            :label="$t('FLOW.OUTSTANDING_APNMT.TITLE')"
            :error="$v.content.title.$error ? ($v.content.title.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.content.title.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            @blur="$v.content.title.$touch"
          />
         <woot-input
            v-model="content.subtitle"
            :label="$t('FLOW.OUTSTANDING_APNMT.TITLE')"
            :error="$v.content.subtitle.$error ? ($v.content.subtitle.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.content.subtitle.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            @blur="$v.content.subtitle.$touch"
          />
          -->
         <woot-input
            v-model="content.reschedule_btn"
            :label="$t('FLOW.APPNMT_CARD.RESCHEDULE_BTN_LABEL')"
            :error="$v.content.reschedule_btn.$error ? ($v.content.reschedule_btn.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.content.reschedule_btn.$error }"
            type="text"
            :placeholder="$t('FLOW.APPNMT_CARD.RESCHEDULE_BTN_PLACEHOLDER')"
            @blur="$v.content.reschedule_btn.$touch"
          />
         <woot-input
            v-model="content.cancel_btn"
            :label="$t('FLOW.APPNMT_CARD.CANCEL_BTN_LABEL')"
            :error="$v.content.cancel_btn.$error ? ($v.content.cancel_btn.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.content.cancel_btn.$error }"
            type="text"
            :placeholder="$t('FLOW.APPNMT_CARD.CANCEL_BTN_PLACEHOLDER')"
            @blur="$v.content.cancel_btn.$touch"
          />



      </div>


      <div class="modal-footer">
        <woot-button @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>

    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import DashboardIcon from '../../../../../../../shared/components/FluentIcon/DashboardIcon.vue';
import FlowApi from '../../FlowApi';
import { required, minValue, maxValue,  minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

// card title,

export default {
  components: {
    FluentIcon,
    DashboardIcon,
  },

  mixins: [alertMixin],
  props: {
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    content: {
      required: true,
    }
  },
  data() {
    return {
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),

  },
  mounted(){

  },
  watch: {

  },

  validations: {
    botid: {
      required,
    },
    content: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(60),
      },
      subtitle: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(60),
      },
      
      // reschedule_btn:{
      //   required,
      //   minLength: minLength(1),
      //   maxLength: maxLength(20),
      // },
      // cancel_btn:{
      //   required,
      //   minLength: minLength(1),
      //   maxLength: maxLength(20),
      // },
    },
  },
  methods: {
    async allDone() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert("Please fill in all required fields correctly.");
        return;
      }
      this.$emit('content-edit-done', this.content);
    },

    onClose() {
      this.$emit('on-close');
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

</style>
