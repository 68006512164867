/* global axios */

import ApiClient from '../ApiClient';

class CatalogueItemsApi extends ApiClient {
  constructor() {
    super('catalog_items', { accountScoped: true });
  }

  // [catalog] create a catalog item
  createCatalogueItem(payload){
    return axios.post(`${this.url}`,payload);
  }
  // [catalog] update a catalog item
  updateCatalogueItem(catalogue_id,payload){
    return axios.patch(`${this.url}/${catalogue_id}`,payload);
  }
  // [catalog] delete a catalog item
  deleteCatalogueItemById(catalogue_id,){
    return axios.delete(`${this.url}/${catalogue_id}`);
  }
/*
  // get list of all catelog by category_id
  getCatelogueItemsByCategoryId(categoryId){
    return axios.get(`${this.url}/items?categories=${categoryId}`);
  }
  getCatelogueItemByCategoryId(catelogueId){
    return axios.get(`${this.url}/?id=${catelogueId}`);
  }
  */

  // [catalog] get list of categories
  // createCatalogueItem(payload){
  //   return axios.post(`${this.url}`,payload);
  // }

  // [catalog] get list of categories
  getListOfCategories(){
    return axios.get(`${this.url}/product_sets`,);
  }

  // [catalog]get all catalog items
  getAllCatalogueItems(query={}) {
    return axios.get(`${this.url}/show`,{
      params: query,
    });
  }
  // [category] create a category
  createCategory(payload) {
    return axios.post(`${this.url}/create_category`,payload);
  }
  // [category] delete a category
  deleteCategory(payload) {
    return axios.post(`${this.url}/destroy_category`,payload);
  }
  // [category->catalogue] assign a category to a catalog
  assignCategoryToCatalogue(payload) {
    return axios.post(`${this.url}/associate_category`,payload);
  }
  // [category->catalogue] remove a category to a catalog
  removeCategoryToCatalogue(payload) {
    return axios.post(`${this.url}/deassociate_category`,payload);
  }




}

export default new CatalogueItemsApi();
